/* Brand x theme */
:root {
  /* BXT blues */
  --light-blue: #1a86ec;
  --primary-blue: #005bb0;
  --dark-blue: #081d55;
  /* BXT accents */
  --rubber-duck-yellow: #ffd45c;

  /* status colours */
  --status-unknown: #0288d1;
  --status-ok: #4caf50;
  --status-unstable: rgb(189, 200, 33);
  --status-risk: #ffd642;
  --status-warning: #ffb74d;
  --status-alert: #f44336;
}
